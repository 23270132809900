import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined'

import { usePdfModal } from 'src/hooks'
import { ImageModal } from 'src/components/modal/image-modal'
import { PdfModal } from 'src/components/modal/pdf-modal'
import { theme } from 'src/providers/theme-provider/theme.provider'

import { IScanMediaInfo } from 'src/types'

import { COLORS } from 'src/styles/common'
import { PdfStubIcon, UrlIcon } from 'src/assets/icons'
import { HandClickIcon } from 'src/assets/icons/v2/HandClickIcon'
import { VideoModal } from 'src/components/modal/video-modal'

interface IModalItemProps {
  media: IScanMediaInfo[]
}

export const MediaSection = ({ media = [] }: IModalItemProps) => {
  const [t] = useTranslation()

  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null)
  const [videoPreview, setVideoPreview] = useState<string | null>(null)

  const {
    handleClosePdfPreview,
    handleError,
    handleLoad,
    handleLoadStart,
    iframeRef,
    isPdfVisible,
    loadError,
    pdfPreview,
    isLoading,
    setIsPdfVisible,
    setPdfPreview,
  } = usePdfModal()

  const handleMediaClick = (mediaItem: IScanMediaInfo) => {
    if (mediaItem.type === 'url') {
      window.open(mediaItem.link, '_blank')
    } else if (mediaItem.type.includes('image')) {
      setFullscreenImage(mediaItem.link || mediaItem.link_medium)
    } else if (mediaItem.type === 'application/pdf') {
      setPdfPreview(mediaItem.link)
      setIsPdfVisible(true)
    } else if (mediaItem.type === 'video/mp4') {
      setVideoPreview(mediaItem.link)
    }
  }

  const handleCloseFullscreen = () => {
    setFullscreenImage(null)
  }

  const handleCloseVideoPreview = () => {
    setVideoPreview(null)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography sx={{ fontSize: '12px', fontWeight: 600, color: COLORS.GREY_LIGHT, mt: '4px' }}>
        <strong>{t('blockchain-verified-modal-media-lable')}:</strong>
      </Typography>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        {media.map((mediaItem, index) => (
          <Grid item key={index} xs={6} sm={4} md={3}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'transform 200ms, filter 200ms',
                willChange: 'transform, filter',
                '&:hover': {
                  filter: 'brightness(90%)',
                  transform: 'scale(0.98)',
                },
              }}
              onClick={() => handleMediaClick(mediaItem)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  maxHeight: '120px',
                  maxWidth: '100%',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  border: `1px solid ${COLORS.GREY_LIGHT}`,
                  zIndex: 103,
                }}
              >
                {mediaItem.type.includes('image') ? (
                  <Box sx={{ position: 'relative' }}>
                    <img
                      src={mediaItem.link_medium || mediaItem.link_small || mediaItem.link}
                      alt={mediaItem.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: '8px',
                        bgcolor: 'rgba(238, 238, 238, 0.5)',
                      }}
                    >
                      <HandClickIcon size={40} stroke={theme.palette.common.white} />
                    </IconButton>
                  </Box>
                ) : mediaItem.type === 'application/pdf' ? (
                  <PdfStubIcon size="100%" />
                ) : mediaItem.type === 'video/mp4' ? (
                  <VideoFileOutlinedIcon sx={{ width: '100%', height: '100%' }} />
                ) : mediaItem.type === 'url' ? (
                  <UrlIcon size="100%" />
                ) : null}
              </Box>

              <Typography variant="caption" sx={{ p: '6px 12px', wordBreak: 'break-word' }}>
                {mediaItem.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Fullscreen Modal for Image */}
      <ImageModal imageSrc={fullscreenImage} handleClose={handleCloseFullscreen} />

      {/* Fullscreen Modal for PDF */}
      <PdfModal
        handleClosePdfPreview={handleClosePdfPreview}
        handleError={handleError}
        handleLoad={handleLoad}
        handleLoadStart={handleLoadStart}
        iframeRef={iframeRef}
        isLoading={isLoading}
        isPdfVisible={isPdfVisible}
        loadError={loadError}
        pdfPreview={pdfPreview}
      />

      {/* Fullscreen Modal for Video */}
      <VideoModal videoSrc={videoPreview} handleClose={handleCloseVideoPreview} />
    </Box>
  )
}
