import { useEffect, useState } from 'react'

export const useCustomModal = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [open])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return {
    open,
    setOpen,
    handleOpen,
    handleClose,
  }
}
