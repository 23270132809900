import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CustomModal } from '.'
import { MutableRefObject } from 'react'

interface IProps {
  pdfPreview: string
  handleClosePdfPreview: () => void
  isPdfVisible: boolean
  isLoading: boolean
  loadError: boolean
  iframeRef: MutableRefObject<HTMLIFrameElement>
  handleLoad: () => void
  handleLoadStart: () => void
  handleError: () => void
}

export const PdfModal = ({
  pdfPreview,
  handleClosePdfPreview,
  isPdfVisible,
  isLoading,
  loadError,
  iframeRef,
  handleLoad,
  handleLoadStart,
  handleError,
}: IProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <CustomModal
      ariaLabel="modal-pdf"
      open={!!pdfPreview}
      handleClose={handleClosePdfPreview}
      showCloseButton
      hide={!isPdfVisible}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        visibility: isPdfVisible ? 'visible' : 'hidden',
      }}
    >
      <Box
        sx={{
          width: '85vw',
          height: '95vh',
          border: 'none',
          '@media (max-width:768px)': {
            width: '100vw',
            height: '80vh',
          },
        }}
      >
        {isMobile ? (
          <>
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loadError && (
              <iframe
                ref={iframeRef}
                sandbox="allow-scripts allow-same-origin"
                src={`https://docs.google.com/viewer?url=${pdfPreview || ''}&embedded=true`}
                title="PDF Preview"
                onLoad={handleLoad}
                onLoadStart={handleLoadStart}
                onError={handleError}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  display: isLoading ? 'none' : 'block',
                }}
              />
            )}
            {loadError && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  bgcolor: 'white',
                }}
              >
                <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                  Failed to load PDF
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => window.open(pdfPreview || '', '_blank')}
                  sx={{ mb: 2, borderRadius: '10px' }}
                >
                  Open file in new window
                </Button>
              </Box>
            )}
          </>
        ) : (
          <iframe
            src={pdfPreview || ''}
            title="PDF Preview"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        )}
      </Box>
    </CustomModal>
  )
}
